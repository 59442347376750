<template>
  <div class="left-menu">
    <el-scrollbar ref="menuScroller" class="menu-scroll">
      <ul class="console-meun">
        <li @click.stop="searchMenu" @mouseover="inSearchMenu" @mouseout="outSearchMenu" class="left-more-menu">
          <div class="root-menu">
            <i :class="[showSearch ? 'el-icon-arrow-left' : 'el-icon-arrow-right']"></i>
          </div>
          <console-menu-search :menu-data="rootMenu" :mxtype="menuData.mxtype" :show="showSearch">
          </console-menu-search>
        </li>
        <template v-for="item in rootMenu">
          <!--saas56独立模块显示，其余产品在此-->
          <template v-if="item.children && item.hidden == 0 && item.mxtype != 'saas56'">
            <li :mxtype="item.mxtype" :class="{ active: item.url == pathname }" @click="rootMenuClick(item)">
              <!-- 一级 -->
              <div class="root-menu">
                <template v-if="item.iconCls && item.iconCls != ''">
                  <i :class="item.iconcls"></i>
                </template>
                <template v-else>
                  <template v-if="item.url && item.url.endsWith('.html') && defaultRootMenuData[item.mxtype]">
                    <i :class="defaultRootMenuData[item.mxtype]['icon']"></i>
                  </template>
                </template>
                <p>
                  {{ defaultRootMenuData[item.mxtype] ? defaultRootMenuData[item.mxtype]["shortName"] : item.text }}
                </p>
              </div>
            </li>
          </template>
        </template>
      </ul>
      <div class="console-sec-menu">
        <div class="console-sec-menu-text">{{ menuData.text }}</div>
        <el-scrollbar ref="menuScroller" class="menu-scroll">
          <el-menu
            class="tower-menu"
            background-color="#344462"
            router
            text-color="#B5C2D9"
            active-text-color="#fff"
            :default-active="active"
            :collapse="collapse"
            :collapse-transition="true"
            :unique-opened="false"
            mini-style
          >
            <tree :data="menuItem" :show-icon="false" />
          </el-menu>
        </el-scrollbar>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Tree from "./tree";
import ConsoleMenuSearch from "./ConsoleMenuSearch.vue";
export default {
  components: {
    Tree,
    ConsoleMenuSearch,
  },
  props: {
    menuData: {
      type: Object,
    },
  },
  computed: {
    active: function () {
      return this.$route.path;
    },
    menuItem: function () {
      return this.menuData.children;
    },
    ...mapGetters({
      rootMenu: "user/menu",
    }),
  },

  data() {
    return {
      showSearch: false,
      collapse: false,
      pathname: "",
      defaultRootMenuData: {
        home: {
          icon: "el-icon-house",
          shortName: "首页",
        },
        agent: {
          icon: "el-icon-suitcase-1",
          shortName: "网代",
        },
        bid: {
          icon: "el-icon-suitcase",
          shortName: "招投标",
        },
        common: {
          icon: "el-icon-setting",
          shortName: "基础",
        },
        wlhy: {
          icon: "el-icon-truck",
          shortName: "调车",
        },
        manage: {
          icon: "el-icon-set-up",
          shortName: "运营",
        },
        tms: {
          icon: "el-icon-connection",
          shortName: "运输",
        },
        wccy: {
          icon: "el-icon-ship",
          shortName: "无船",
        },
        wms: {
          icon: "el-icon-office-building",
          shortName: "仓储",
        },
        dms: {
          icon: "el-icon-truck",
          shortName: "配送",
        },
        zbrl: {
          icon: "el-icon-files",
          shortName: "人力",
        },
        datav: {
          icon: "el-icon-data-board",
          shortName: "可视化",
        },
        data: {
          icon: "el-icon-data-analysis",
          shortName: "数据云",
        },
        tower: {
          icon: "el-icon-film",
          shortName: "控制塔",
        },
        gr: {
          icon: "el-icon-bank-card",
          shortName: "共融",
        },
        jk: {
          icon: "el-icon-truck",
          shortName: "集卡",
        },
        insu: {
          icon: "el-icon-lock",
          shortName: "保险",
        },
        assistant: {
          icon: "el-icon-thumb",
          shortName: "助手",
        },
        service: {
          icon: "el-icon-suitcase-1",
          shortName: "服务商",
        },
      },
    };
  },
  methods: {
    ...mapActions(["user/setMenuActiveOne", "user/setMenuActive"]),
    menuSelect(index) {
      menuClickLog(this, index);
    },
    rootMenuClick(item) {
      // if (this.pathname == item.url) {
      //   window.open(item.url, "_self");
      // } else {
      //   window.open(item.url, "_blank");
      // }
      console.log("rootMenuClick");
      console.log(item);
      window.open(item.url, item.text);
    },
    searchMenu(event) {
      if (event.target.type == "text") {
        event.stopPropagation();
        return;
      }
      this.showSearch = !this.showSearch;
    },
    inSearchMenu(event) {
      if (!this.showSearch) {
        this.showSearch = true;
      }
    },
    outSearchMenu(event) {
      if (event.target.offsetParent == null) {
        this.showSearch = false;
      }
      if (event.target.offsetParent && !event.target.offsetParent.classList.contains("left-more-menu")) {
        this.showSearch = false;
      }
    },
  },
  mounted() {
    this.pathname = window.location.pathname;
  },
};
</script>
<style>
.left-menu {
  width: 50px;
}

.console-sec-menu-text {
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #b5c2d9;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: #b5c2d9;
}

.console-meun > li {
  color: #ccc;
  color: #303133;
  padding: 10px 5px 10px 5px;
  list-style: none;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}

.console-meun > li.active {
  background: #2a2f32;
  /* border-left: 2px solid #fff; */
}

.console-meun > li:first-child {
  height: 31px;
  line-height: 31px;
  padding: 0;
  background: #3a3f49;
}

.console-meun > li > div.root-menu {
  text-align: center;
}

.console-meun > li.active > div > i {
  color: #fff;
}

.console-meun > li > div > i {
  color: #ccc;
  margin: 0;
  vertical-align: middle;
  width: 22px;
  font-size: 22px;
  text-align: center;
}

.console-meun > li.active > div > p {
  color: #fff;
}

.console-meun > li > div > p {
  color: #ccc;
  font-size: 12px;
}

.console-sec-menu {
  position: fixed;
  width: 130px;
  background: #344462;
  left: 50px;
  color: #b5c2d9;
  top: 50px;
  bottom: 0;
}

/*
  .console-sec-menu::before {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-right: 6px solid transparent;
    border-right-color: rgb(255 255 255 / 0.90);
    content: '';
    position: absolute;
    left: -12px;
    top: 10px;
  } */

.console-sec-menu .el-submenu .el-menu-item {
  min-width: 100px;
}

.console-sec-menu .el-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}

.console-sec-menu .el-menu-item.is-active {
  background-color: #2468f2 !important;
}

.console-sec-menu .el-submenu__title {
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}

.console-sec-menu .el-submenu__icon-arrow {
  right: 10px;
}

.sec-menu {
  display: inline-block;
  width: 100px;
  top: 0;
  position: relative;
  vertical-align: top;
  color: #fff;
  padding: 10px;
  overflow: hidden;
}

.sec-menu-scroll .el-scrollbar__wrap {
  overflow-x: hidden;
}

.sec-menu-scroll {
  height: 500px;
  overflow: hidden;
}

.chd-menu-text {
  color: #999;
}

.chd-menu-text.bold {
  font-weight: bold;
  color: #fff;
  font-size: 12px;
}

.chd-menu-wrap > div > .chd-menu-text {
  padding-left: 20px !important;
}

.chd-menu-wrap .chd-menu-wrap {
  padding-left: 20px !important;
}

.chd-menu-text:hover {
  color: #409eff;
}

.sec-menu span {
  padding: 5px;
  display: block;
}

.el-menu.tower-menu {
  margin-bottom: 50px;
}
</style>
