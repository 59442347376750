<!-- 数据权限选择控件,根据type选择列出 负责的客户或者拥有的部门网点-->
<template>
  <el-select
    @change="changed"
    name="organs"
    autocomplete="new-password"
    :size="size"
    v-model="currentValue"
    value-key="id"
    :multiple="multiple"
    filterable
    clearable
    :style="wStyle"
    :placeholder="placeholder"
    :disabled="disabled"
    v-if="type=='organs'"
  >
    <el-option
      v-for="item in udaOrgans.items"
      :key="item.id"
      :label="item.name"
      :value="useObjVal?item:item.id"
    >{{ item.name }}</el-option>
  </el-select>
  <el-select
    @change="changed"
    :size="size"
    v-model="currentValue"
    autocomplete="new-password"
    value-key="id"
    :multiple="multiple"
    filterable
    clearable
    :style="wStyle"
    :placeholder="placeholder"
    :disabled="disabled"
    v-else
  >
    <el-option
      v-for="item in udaCusts"
      :key="item.id"
      :label="item.name"
      :value="useObjVal?item:item.id"
    >{{ item.name }}</el-option>
  </el-select>
</template>
<script>
import emitter from "element-ui/src/mixins/emitter";
import { mapGetters } from "vuex";
import userAPI from '@/api/userAPI.js'
export default {
  mixins: [emitter],
  components: {},

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    type: {
      type: String,
      default: "organs" //'organs/customers' ，type=organs 显示所属部门的列表，type=customers.显示负责客户的列表
    },
    placeholder: {
      type: String,
      default: "请选择"
    },

    value: {
      type: [String, Number, Object]
    },

    objValue: {
      type: Object,
      twoWay: true
    },

    remote: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number, // 默认140px , 指定宽度  :width="180"
      default: 140
    },
    size: {
      type: String,
      default: "mini" //选择框大小，可选值为large和small或者不填
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: true
    },
    useObjVal: {
      //value 返回对象
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      udaOrgans: "user/uda_organs",
      // udaCusts:"user/uda_customers"
    }),
    wStyle() {
      if (this.autoWidth) {
        return {
          width: `100%`
        };
      } else {
        return {
          width: `${this.width}px`
        };
      }
    }
  },
  methods: {
    changed(val) {
      this.currentValue = val;
      this.$emit("change", val);
      this.$emit("input", this.currentValue);
    },
      //立即重新加载客户，tms客户实时刷
      refreshCustoms(){
          this.loadCustoms();
      },
      reset(){
        this.currentValue = null
        console.log('reset')
      },
      fieldReset(){
        this.currentValue = null
        console.log('fieldReset')
      },
      loadCustoms (callback){
          // userAPI.refreshCustomer().then((response)=>{
          //     if(response.data.success){
          //         this.udaCusts = response.data.uda_customers
          //         if (callback)
          //             callback();
          //     }else{
          //         this.$message.error("加载客户数据失败")
          //         if(callback)
          //         callback();
          //     }
          // })
          let qParams = {};
          qParams.limit = 10000
          qParams.start = 0;
          userAPI.findCustomerList({qp:JSON.stringify(qParams)}).then((response)=>{
              if(response.data.success){
                  this.udaCusts = response.data.data
                  if (callback)
                      callback();
              }else{
                  this.$message.error("加载客户数据失败")
                  if(callback)
                  callback();
              }
          })
      }
  },
  data() {
    return {
      currentValue: this.value,
      udaCusts: []
    };
  },

  watch: {
    value: function(val, oval) {
        // console.log('udaSelector.watch.value')
        let that = this;
        let valId = that.useObjVal? (val?val.id:null): val

        if(valId == null){
          that.currentValue = null
        } else {
          debugger
          //有值
          if(that.type=='customers'){
            let callback = function () {
                console.log(val)
                let valId = that.useObjVal? (val?val.id:null): val              
                if(that.type=='customers' && that.udaCusts.items.filter(item => item.id==valId).length>0){
                    that.currentValue = val
                }
            }
            //重新加载一波，最新的客户数据，防止最新的数据被无情的过滤
            that.loadCustoms(callback);
          } else if (that.type=='organs'){
            //如果val不在可选数据，防止出现赋值不在可选项的的bug
            if(this.udaOrgans.items.filter(item => item.id==val).length>0){
                that.currentValue = val;
            }
          }

          that.dispatch("ElFormItem", "el.form.change", val); //触发所在表单的校验事件     
        }
    }
  },
  mounted() {    
      this.loadCustoms()
  }
};
</script>
