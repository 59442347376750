<template>
  <div id="app">
    <router-view name="header" ref="header"/>
    <div class="app-content">
      <el-scrollbar ref="mainScroller" class="main-scroll">
        <div :class="['home' ,{'is-collapse': isCollapse}]">
          <div class="left">
            <!--  左侧菜单 -->
            <router-view name="menu" />
          </div>

          <div class="main" :class="{phone_only: isMobile}">
            <!-- tab页-->
            <router-view name="pageList" />
            <!-- <page-list></page-list> -->
            <!-- 主体部分 :params="$route.params" -->
            <keep-alive>
              <router-view name="content" :key="$route.path"  />
            </keep-alive>

            <el-dialog
                title="系统更新"
                :visible.sync="anncDialogVisible"
                :close-on-click-modal="false"
                :before-close="handleDialogClose"
                :append-to-body="true"
                width="70%"
            >
              <el-form
                  ref="anncInfo"
                  :model="anncInfo"
                  label-width="105px"
                  label-position="right"
                  style="padding-top:10px;"
              >
                <el-row :gutter="15">

                  <el-col :span="8">
                    <el-form-item label="公告标题" prop="title">
                      {{anncInfo.title}}
                    </el-form-item>
                  </el-col>
<!--                  <el-col :span="8">-->
<!--                    <el-form-item label="公告类型" prop="category">-->
<!--                      {{anncInfo.category ? anncInfo.category.name : ""}}-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
<!--                  <el-col :span="8">-->
<!--                    <el-form-item label="公告状态" prop="status">-->
<!--                      <el-tag type="danger" size="medium" style="margin-left:20px">-->
<!--                        {{ anncInfo.status ? anncInfo.status : "新建" }}-->
<!--                      </el-tag>-->
<!--                    </el-form-item>-->
<!--                  </el-col>-->
                </el-row>
                <el-row>
                  <el-form-item label="内容" prop="content">
                    <div v-html="anncInfo.content" class="contentBorderCss"></div>
                  </el-form-item>
                </el-row>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="anncDialogVisible=false">确 定
                </el-button>
              </div>
            </el-dialog>
          </div>
        </div> 
      </el-scrollbar>
    </div>
     <toolBar v-if="showToolBar"></toolBar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import aEntityAPI from "@/api/aEntityAPI";
import editor from '@/components/editor/editor'
import toolBar from '@/components/toolBar/toolBar'

export default {
  components: {
    editor,
    toolBar
  },
  computed: {
    isMobile() {
      const userAgentInfo = navigator.userAgent
      const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
      let flag = false
      for (let i = 0; i < agents.length; i++) {
        if (userAgentInfo.indexOf(agents[i]) > -1) {
          flag = true
          break
        }
      }
      return flag
    },
    ...mapGetters({
      userinfo: "user/userinfo",
      isCollapse: "layout/isCollapse",
      sysConfig: "config/sysConfig"
    })
  },

  data() {
    return {
        showToolBar: false,
        anncInfo: {
          title: "",
          category: "",
          status: "",
          content: ""
        },
        anncDialogVisible: false,
        hrsaasImg: require("@/assets/hrsaas/index/hr_saas_logo.png"),
        saasImg: require("@/assets/saas_logo.png"),
    };
  },
  methods: {
    ...mapActions(["user/checkLogin", "config/checkConfig","layout/setScrollTop"]),
    handleScroll(){
      let scrollbarEl = this.$refs.mainScroller.wrap
      scrollbarEl.onscroll = () => {
         this["layout/setScrollTop"](scrollbarEl.scrollTop)
      }
    },

    handleDialogClose(done) {
      this.anncInfo = {};
      this.$refs.anncInfo.resetFields();
      done();
    },

    queryTaskCount(){
        // alert('app.testInvoke')
        this.$refs.header.queryTaskCount();
    }
  },
  watch: {
    // sysConfig: function(nval, oval) {
    //   if (nval && nval.logoFile && nval.logoFile.filePath) {
    //     var link = document.createElement("link");
    //     link.type = "image/x-icon";
    //     link.rel = "shortcut icon";
    //     link.href =  this.__ftpPath + nval.logoFile.filePath;
    //     document.getElementsByTagName("head")[0].appendChild(link);
    //   }
    // }

      sysConfig: function (nval, oval) {
          if (nval && nval.logoFile && nval.logoFile.filePath) {
              var link = document.createElement("link");
              link.type = "image/x-icon";
              link.rel = "shortcut icon";

              var serverHost = window.location.host;
              if (serverHost.indexOf("hrsaas")>-1 || serverHost.indexOf("saas.gz-vip.com") >-1){
                  link.href = this.hrsaasImg;
              } else if (serverHost.indexOf("saas")>-1) {
                  link.href = this.saasImg;
              } else {
                  link.href = this.__ftpPath + nval.logoFile.filePath;
              }
              var url = window.location.href;
              debugger
              if (this.sysConfig.DigitalDispatcherConfig.valid && (url.indexOf("wlhy.") > -1 || url.indexOf("tms.") > -1)) {//运输或者调车界面底部展示弹框(数字调度员)
                  this.showToolBar = true;
              }
              document.getElementsByTagName("head")[0].appendChild(link);
          }
      }
  },
 created() {
   
  },
  mounted() {
    this["user/checkLogin"]();
    this["config/checkConfig"]();

    var that = this;
    aEntityAPI.request('WebMsgService', 'getLatestMsg', {'userId': this.userinfo.id}).then(response => {
      if (response.data.success) {
        const data = response.data.data;
        if(response.data.success){
          if (data != null) {
            that.anncInfo = data;
            that.$set(that.anncInfo, "category", {name: data.category, id: data.categoryId});
            console.log(that.anncInfo,"that.anncInfo")
            that.anncDialogVisible = true;
          }
        }

      } else {
        this.$message.error(response.data.msg || '请求失败');
      }
    });

    //监听滚动
     this.handleScroll()
  }
};
</script>
<style>
html,
body {
  height: 100%;
}

body {
  overflow: hidden;
}

#app {
  height: 100%;
  overflow: hidden;
}

.app-content {
  height: 100%;
  padding: 0;
  margin-top: 0;
  height: 100%;
  min-height: auto;
}

.home {
  position: relative;
  top: 51px;
  position: relative;
  height: 100%;
}

.left {
  position: fixed;
  width: 180px;
  left: 0;
  top: 50px;
  bottom: 0px;
  z-index: 9;
}

.home.is-collapse > .left {
  width: 64px;
}

.main {
  position: relative;
  left: 195px;
  right: 15px;
  top: 45px;
  bottom: 15px;
  width: calc(100% - 210px);
}

.main.phone_only {
  overflow-x: auto;
  min-width:  200px;
}

.main-scroll {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.home.is-collapse > .main {
  left: 79px;
  width: calc(100% - 94px);
}

.main-scroll > .el-scrollbar__wrap {
  overflow-x: hidden;
}

.main-scroll > .el-scrollbar__wrap > .el-scrollbar__view .main > div {
  min-width: 1170px;
}

.main-scroll > .el-scrollbar__wrap > .el-scrollbar__view .main > div.grid-wrap {
  min-width: unset;
}

.main-scroll
  > .el-scrollbar__wrap
  > .el-scrollbar__view
  .main
  > div.unset-min-width {
  min-width: unset;
}

.main-scroll > .el-scrollbar__bar.is-horizontal:hover {
  height: 10px;
}

.main-scroll > .el-scrollbar__bar.is-vertical:hover {
  width: 10px;
}
.contentBorderCss{
  width: 95%;
  height: 330px;
  border: 1px solid #c9d8db;
  box-sizing: border-box;
  overflow: auto;
}
</style>