<template>
    <div class="HTable">
        <div class="settingBox" v-if="setColumn">
            <el-tooltip class="item" effect="dark" content="点击设置表格列" placement="bottom-start">
                <el-button icon="el-icon-setting" @click="openDialog" size="medium" type="text" class="setting-icon"></el-button>
            </el-tooltip>
        </div>
        <el-dialog width="70%" title="自定义显示字段" :visible.sync="dialogVisible" destroy-on-close :show-close="false" :before-close="handleDialogClose">
            <el-row>
                <el-col :span="24">
                    <el-card>
                        <div slot="header" class="card-header">
                            <span>全部字段</span>
                        </div>
                        <el-checkbox-group v-model="selectCol" @change="handleChangeSelectColumn">
                            <vuedraggable animation="180" @end="onEnd">
                                <el-checkbox v-for="item in col" :key="item.prop" :label="item.prop" :disabled="item.disabled" class="col-list">
                                    {{ item.label }}
                                </el-checkbox >
                            </vuedraggable>
                        </el-checkbox-group>
                    </el-card>
                </el-col>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </el-dialog>

        <el-table
            v-bind="$attrs"
            :data="tableData"
            v-on="$listeners"
            :key="JSON.stringify(checkedCol)"
            ref="listData"
            v-loading="loading || handleLoading"
            @selection-change="handleSelect"
            :row-style="rowClass"
            use-virtual
            :row-height="30"
        >
            <el-table-column v-if="setColumn" width="36"></el-table-column>
            <el-table-column type="selection" width="60" align="center" reserve-selection v-if="showSelection" v-bind="$attrs"></el-table-column>
            <el-table-column
                v-for="(item, index) in checkedCol"
                :key="item.prop"
                v-bind="item"
                :index="index"
                :column-key="item.prop"
            >
                <template v-if="item.slotHeaderName" v-slot:header="scope">
                    <slot :name="item.slotHeaderName" v-bind="scope" :item="item"></slot>
                </template>
                <template v-if="item.slotName" v-slot:default="scope">
                    <slot :name="item.slotName" v-bind="scope"></slot>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import vuedraggable from 'vuedraggable'
import aEntityAPI from "@/api/aEntityAPI.js";
import { deepCopy } from "@/utils/utils.js";
import {mapGetters} from 'vuex';
export default {
    name: "HTable",
    components: { vuedraggable },
    props: {
        columns: {
            type: Array,
            default() {
                return [];
            },
        },
        data: {
            type: Array,
            default() {
                return [];
            },
        } ,
        setColumn: {
            type: Boolean,
            default: true,
        },
        showSelection: {
            type: Boolean,
            default: false
        },
        loading:{
            type: Boolean,
            require: false,
            default: false
        },
        showLoading:{
            type: Boolean,
            require: false,
            default: false
        },
    },
    computed: {
        ...mapGetters({
            userinfo: "user/userinfo"
        }),
    },
    watch: {
        columns: {
            handler(newVal) {
                this.col = newVal.map(
                    (item, index) => (item = { ...item, index, checked: item.checked || true })
                );
                this.handleColChange();
            },
            immediate: true,
        },
        data: {
            handler(newVal) {
                this.tableData = [...newVal];
            },
            immediate: true,
        },
    },
    data() {
        return {
            pageUrl:"",
            handleLoading: false,
            dialogVisible: false,
            tableData: [],
            col: [],
            tempCol:[],
            checkedCol: [],
            selectCol: [],
            tableColumnsId: null,
            selectedRow: [], //已选的行
            multipleSelection: [],
        };
    },
    methods: {
        openDialog(){
            this.dialogVisible = true;
            this.tempCol = deepCopy(this.col);
        },
        handleChangeSelectColumn(){
            this.col.forEach((item) => {
                if (this.selectCol.includes(item.prop)) {
                    this.$set(item, "checked", true);
                } else {
                    this.$set(item, "checked",false);
                }
            });
            this.handleColChange();
        },
        handleClose(item){
            this.handleColChange();
            this.handleChangeSelectColumn();
        },
        cancel(){
            this.col = deepCopy(this.tempCol);
            this.handleColChange();
            this.dialogVisible = false;
        },
        handleDialogClose(done){
            this.col = deepCopy(this.tempCol);
            this.handleColChange();
            done();
        },
        onEnd(event){
            const [oldIndex,newIndex] = [event.oldIndex, event.newIndex];
            let direction,min,max;
            if( oldIndex < newIndex){
                direction = "back";
                min = oldIndex;
                max = newIndex;
            }else{
                direction = "forward";
                min = newIndex;
                max = oldIndex;
            }
            if( direction === "forward"){
                this.$set(this.col[max],"index", min);
                for(let i = min;  i < max ; i++ ){
                    const index = this.col[i].index + 1;
                    this.$set(this.col[i],"index",index)
                }
            }else{
                this.$set(this.col[min],"index", max);
                for(let i = max;  i > min ; i-- ){
                    const index = this.col[i].index - 1;
                    this.$set(this.col[i],"index",index)
                }
            }

            this.col.sort((a, b) => {
                return a.index - b.index;
            });
            this.handleColChange();

        },
        save(){
            this.dialogVisible = false;
            let tableColumns ={
                user: this.userinfo,
                col: JSON.stringify(this.col),
                url: this.pageUrl,
            }
            if(this.tableColumnsId){
                tableColumns.id = this.tableColumnsId;
            }
            aEntityAPI.request("TableColumnsService","saveEntity",{tableColumns:JSON.stringify(tableColumns)}).then(res=>{
                if( res.data.success){
                    this.tableColumnsId = res.data.data.id
                    this.$emit("columnsChange",JSON.stringify(this.checkedCol));
                }else{
                    this.$message.error(res.data.msg || "保存用户列配置失败")
                }
            });
        },
        getColByUrl(){
            if( this.showLoading){
                this.handleLoading = true;
            }
            aEntityAPI.request("TableColumnsService","getEntityByUrl",{url:this.pageUrl,userId:this.userinfo.id}).then(res=>{
                if(res.data.success){
                    if(res.data.data){
                        const savedCols = JSON.parse(res.data.data.col);
                        let isEditByDev = this.compareSavedColsAndColumnsCols(savedCols,this.col);

                        if( !isEditByDev ){
                            this.col = JSON.parse(res.data.data.col);
                            this.col.sort((a, b) => {
                                return a.index - b.index;
                            });
                            this.handleColChange();
                        }
                        this.tableColumnsId = res.data.data.id
                    }
                    this.$emit("columnsChange",JSON.stringify(this.checkedCol));
                    this.handleLoading = false;
                }else{
                    this.$message.error(res.data.msg || "加载用户列配置失败")
                    this.handleLoading = false;
                }
            });
        },
        //比较页面传过来的col和数据库存储的col，可能开发更改了页面，这时默认数据库存储不生效
        compareSavedColsAndColumnsCols(savedCols,columnsCols){
            if(savedCols.length != columnsCols.length ){
                return true;
            }else{
                for(let i = 0 ; i < savedCols.length; i++){
                    const savedCol = savedCols[i];
                    const columnsCol = columnsCols.find( o => o.prop === savedCol.prop );

                    if( !columnsCol ) {
                        return true;
                    }else{
                        let savedColKeys = Object.keys(savedCol);
                        let columnsColKeys = Object.keys(columnsCol);
                        if( savedColKeys.length !== columnsColKeys.length){
                            return true;
                        }

                        for(let key in columnsCol){
                            if( key !== "index" && key !== "checked" && (!savedCol[key] || savedCol[key] !== columnsCol[key])){
                                return true;
                            }
                        }
                    }
                }

                return false;
            }
        },
        handleColChange(){
            const tempCheckedCol = this.col.filter((item) => item.checked);
            this.checkedCol.splice(0,this.checkedCol.length);
            tempCheckedCol.forEach( item => this.checkedCol.push(item));

            const tempSelectCol = this.checkedCol.map((item) => (item = item.prop));
            this.selectCol.splice(0,this.selectCol.length);
            tempSelectCol.forEach( item => this.selectCol.push(item));
        },
        rowClass({ row, rowIndex }) {
            if(row.HTableColor){
                row.HTableColor = false;
                return { "background-color": "rgba(185, 221, 249, 1)" };
            }else{
                return { "background-color": "" };
            }
        },
        handleSelect(selection) {
            let _this = this;
            _this.multipleSelection = [];
            _this.selectedRow = selection;
            for (var i = 0; i < selection.length; i++) {
                selection[i].HTableColor = true;
            }
        },
    },
    created(){
        this.pageUrl = window.location.href;
        this.getColByUrl();
    },

};
</script>

<style lang="less" scoped>
    .HTable {
        position: relative;

        .setting-icon {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 36px;
            height: 36px;
            text-align: center;
            font-size: 20px;
            line-height: 36px;
            color: #909399;
            cursor: pointer;
            margin-top: -10px;
        }

        .card-header{
            line-height: 0px;
        }

        .col-list{
            line-height:2;
            margin-right:20px;
        }

        .tag-list{
            margin-top: 2px;
            width: 150px;
        }

        .el-icon-close{
            margin-right:1px !important;
        }

        /deep/  .el-checkbox__label  {
            width: 80px
        }

    }
</style>